<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import countTo from "vue-count-to";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import tableData from "@/components/tablecomponent";

// import SellingProduct from './selling-product';
// import TopUsers from './top-users';
// import Activity from './activity';
// import SocialSource from './social-source';

export default {
  page: {
    title: "วิเคราะห์ข้อมูลลูกค้ามุ่งหวัง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    countTo,
    DatePicker,
    Multiselect,
    tableData,
  },
  data() {
    return {
      listTyeOptions: [
        {
          typeId: "U",
          nameTh: "รายบุคคล",
        },
        {
          typeId: "T",
          nameTh: "แบบทีม",
        },
      ],
      userPriority: 0,
      showTB: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "name_th",
          label: "ชื่อ",
        },
        {
          key: "family_name_th",
          label: "นามสกุล",
        },
        {
          key: "mobile_phone",
          label: "เบอร์โทร",
        },
        {
          key: "email",
          label: "อีเมล",
        },
        {
          key: "full_address",
          label: "ที่อยู่",
        },
      ],
      optionYear: [
        { id: "2022" },
        { id: "2021" },
        { id: "2020" },
        { id: "2019" },
      ],
      startYear: 0,
      endYear: 0,
      titleModal: "รายชื่อลูกค้าที่ต้องติดต่อ",
      listType: "",
      chart: false,
      title: "วิเคราะห์ข้อมูลลูกค้ามุ่งหวัง",
      branchId: [],
      localData: [],
      localDataBranch: [],
      dataDashboard: [],
      branchItem: "",
      dataDashboardKpi: [],
      overlayFlag: false,
      startDate: "",
      endDate: "",
      currentPage: 1,
      perPage: 100,
      //   Teamseries:[],
      load: false,
      isLoading: false,
      DateSearch: "",
      branchIdArr: [],
      topUsers: [],
      items: [
        {
          text: "SuriyaIT",
        },
        {
          text: "วิเคราะห์ข้อมูล",
        },
        {
          text: "วิเคราะห์ข้อมูลลูกค้ามุ่งหวัง",
          active: true,
        },
      ],
      //* compare Data
      compareData: [],
      optionsCompare: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [5, 7, 5],
          curve: "straight",
          dashArray: [0, 8, 5],
        },
        title: {
          text: "จำนวนลูกค้ามุ่งหวังในแต่ละปี",
          align: "left",
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              ""
            );
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        xaxis: {
         type: "category",
        },
        yaxis: [
          {
            title: {
              text: "คน",
              style: {
                color: "#000",
              },
            },
          },
        ],
        tooltip: {
          y: [],
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      seriesCompare: [],

      //* End compare Data
      //! Pie Vehicle Type
      seriesVehicleTypePie: [], //! ค่่าลูกค้า
      chartOptionsVehicleTypePie: {
        legend: {
          position: "top",
          // height: 100,
        },
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      //! End pie Vehicle Type

      //! Pie Salary Type
      seriesSalaryPie: [], //! ค่่าลูกค้า
      chartOptionsSalaryPie: {
        legend: {
          position: "top",
          // height: 100,
        },
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      //! End pie Salary Type

      //! Pie Occ Type
      seriesOccPie: [], //! ค่่าลูกค้า
      chartOptionsOccPie: {
        legend: {
          position: "top",
          // height: 100,
        },
        chart: {
          width: 380,
          type: "donut",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      //! End pie Occ Type

      //! Pie
      seriesPie: [], //! ค่่าลูกค้า
      chartOptionsPie: {
        legend: {
          position: "top",
          // height: 100,
        },
        chart: {
          width: 380,
          type: "donut",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      //! End pie

      //! Pie Team
      seriesPieTeam: [], //! ค่่าลูกค้า
      chartOptionsPieTeam: {
        legend: {
          position: "top",
          // height: 100,
        },
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //! End pie Team

      //! Pie Team
      seriesSocialPie: [], //! ค่่าลูกค้า
      chartOptionsSocialPie: {
        legend: {
          position: "top",
          // height: 100,
        },
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [], //! ชื่อสาขา
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      //! End pie Team
      optionTeam: [],
      teamId: [],

      optionsTeam: {
        chart: {
          id: "Team",
        },
        xaxis: {
          title: "จำนวนลูกค้ามุุ่่งหวัง",
          categories: [],
        },

        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        colors: ["#5b73e8"],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(y) {
              if (typeof y !== "undefined") {
                return y + " คน";
              }
              return y;
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        title: {
          text: "จำนวนลูกค้ามุ่งหวัง (คน)",
          align: "center",
          floating: true,
        },
      },
      Teamseries: [
        {
          type: "bar",
          name: "จำนวนลูกค้ามุ่งหวัง (คน)",
          data: [],
        },
      ],
    };
  },
  created() {
    this.getNow();
    this.getLocalData();
    // console.log(this.optionsCompare.xaxis.categories);
  },
  mounted() {},
  methods: {
    caltoY(){
      this.optionsCompare.xaxis.categories = [];
      for (let i = this.startYear.id; i <= this.endYear.id; i++) {
              this.optionsCompare.xaxis.categories.push(`${i}`);
        }
        // console.log(this.optionsCompare.xaxis.categories);
    },
    calCompare(y) {
      let count = 0;
      // let percent = 0;
      for (let i = 0; i < y.length; i++) {
        // console.log(y[i]);
        // var keys = Object.values(y[i]);
        count += parseInt(Object.values(y[i]));
      }

      return count;
    },
    
    calComparePer(y) {
      let count = 0;
      let num = [];

      let percent = 0;
      for (let i = 0; i < y.length; i++) {
        count += parseInt(Object.values(y[i]));
        num.push(parseInt(Object.values(y[i])));
      }

      let min = Math.min(...num);
      percent = count - min;
      // console.log("plus =>", percent);

      return percent;
    },
    checkPermission() {
     
        this.getDataKpiTeam();
        this.getTeam();
        // this.getCompare();
      
      this.getData();
    },
    showmodal() {
      // console.log(this.dataDashboard.data);
      this.showTB = this.dataDashboard.data;
      this.$refs["modalShow"].show();
    },
    handleHide() {
      this.showTB = [];
    },
    getCompare() {
        this.load = false;
        this.overlayFlag = true;
        
        this.caltoY();
      
        
        this.logData();
        

        useNetw
          .get("api/crm-analytics/compare-year", {
            params: {
              branchId: !this.branchIdArr
                ? [this.branchItem]
                : this.branchIdArr,
              startYear: this.startYear.id,
              endYear: this.endYear.id,
            },
          })
          .then((response) => {
            this.compareData = response.data[0];
            const comPData = response.data[0];
            
            this.seriesCompare = [];


            

            comPData.forEach((item) => {
            let data = [];
            for (let i = 0; i < item.year.length; i++) {
              data.push({
                x: Object.keys(item.year[i]),
                y: parseInt(Object.values(item.year[i])),
              });
            }
            this.seriesCompare.push({
              name: item.nameTh,
              type: "line",
              data: data,
            });
          });
            this.getData();
          
            
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.chart = true;
            this.load = true;
            this.overlayFlag = false;
          });
      
        
      
    },
    getTeam: function() {
      this.logData();
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/crm-analytics/dealer-team", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            // branchId: !this.branchIdArr ? [this.branchItem] :this.branchIdArr,
            branchId: this.branchItem,
          },
        })
        .then((response) => {
          this.optionTeam = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false; //skeleton false
        });
    },
    logData() {
      const arr = [];
      this.branchId.forEach((item) => {
        arr.push(item.branchId);
      });
      this.branchIdArr = arr;
    },
    limitText(count) {
      return `and ${count} other Branch`;
    },
    getData() {
      useNetw

        .get("api/crm-analytics", {
          params: {
            branchId: !this.branchIdArr ? [this.branchItem] : this.branchIdArr,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then((response) => {
          //   console.log("Data=>", response.data);
          this.dataDashboard = response.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    getDataKpiTeam() {
      this.logData();
      this.load = false;
      this.overlayFlag = true;
      useNetw

        .get("api/crm-analytics/kpi-team", {
          params: {
            branchId: !this.branchIdArr ? [this.branchItem] : this.branchIdArr,
            startDate: this.startDate,
            endDate: this.endDate,
            listType: !this.listType.typeId ? "T" : this.listType.typeId,
          },
        })
        .then((response) => {
          this.topUsers = response.data.countTopFiveSale;
          this.dataDashboardKpi = response.data.data;

          const Teams = [];
          const TeamSeries = [];

          //! Start Pie Occ Type
          const pieOcc = response.data.countOccupation;
          this.chartOptionsOccPie.labels = [];
          this.seriesOccPie = [];

          pieOcc.forEach((itemPage) => {
            this.chartOptionsOccPie.labels.push(itemPage.nameTh);
            this.seriesOccPie.push(itemPage.count);
          });
          //! End Pie Social Occ Type

          //! Start Pie Salary Type
          const pieSalary = response.data.countSalary;
          this.chartOptionsSalaryPie.labels = [];
          this.seriesSalaryPie = [];

          pieSalary.forEach((itemPage) => {
            this.chartOptionsSalaryPie.labels.push(itemPage.nameTh);
            this.seriesSalaryPie.push(itemPage.count);
          });

          //! End Pie Social Salary Type

          //! Start Pie Vehicle Type
          const pieVm = response.data.countTypeVehicle;
          this.chartOptionsVehicleTypePie.labels = [];
          this.seriesVehicleTypePie = [];

          pieVm.forEach((itemPage) => {
            this.chartOptionsVehicleTypePie.labels.push(itemPage.nameTh);
            this.seriesVehicleTypePie.push(itemPage.count);
          });

          //! End Pie Social Vehicle Type

          //! Start Pie Social
          const pieSocial = response.data.ppCusSocial;
          this.chartOptionsSocialPie.labels = [];
          this.seriesSocialPie = [];

          pieSocial.forEach((itemPage) => {
            this.chartOptionsSocialPie.labels.push(itemPage.nameTh);
            this.seriesSocialPie.push(itemPage.count);
          });

          //! End Pie Social

          //? Test data Branch
          const Team = response.data.data;
          Team.forEach((itemTeam) => {
            if (this.listType.typeId == "U") {
              itemTeam.users.forEach((item) => {
                Teams.push(`${item.salesPerson}`);
                TeamSeries.push(item.prospectCustomers);
              });
            } else {
              itemTeam.teams.forEach((item) => {
                Teams.push(`${item.name}`);
                TeamSeries.push(item.prospectCustomers);
              });
            }
            this.optionsTeam.xaxis.categories = Teams;
            this.Teamseries[0].data = TeamSeries;
            // console.log(Teams);
          });
          // console.log(Team);

          //? End test data branch

          //! Start graph
          //! End graph

          //! Start Pie
          this.chartOptionsPie.labels = [];
          this.seriesPie = [];

          this.dataDashboardKpi.forEach((itemPage) => {
            this.chartOptionsPie.labels.push(itemPage.branchNameTh);
            this.seriesPie.push(itemPage.prospectCustomers);
          });

          //! End Pie

          this.chart = true;
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
          // console.log(err);
        })
        .then(() => {
          this.load = true;
          this.overlayFlag = false;
        });
    },
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.startDate = date;
      this.endDate = date;
      //   this.DateSearch = "2022-06-07"
      // console.log(this.DateSearch);
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;
      // console.log(this.branchItem);

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = localDataBranch;
      // console.log(user.userPriority);
      this.userPriority = user.userPriority;
      // console.log(this.localDataBranch);
      
        this.getDataKpiTeam();
        this.getTeam();
      
      this.getData();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="mb-3 position-relative">
              <label>สาขา : </label>
              <multiselect
                @input="checkPermission()"
                v-model="branchId"
                :options="localData"
                label="nameTh"
                :show-labels="false"
                :multiple="true"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="false"
                :limit-text="limitText"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                track-by="branchId"
              >
              </multiselect>
            </div>
          </div>
        </div>
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">หน้าหลัก</span>
            </template>
            <div class="row">
              <!-- <div
                class="col-6 col-sm-6 col-md-6"
                v-if=""
              >
                <div class="mb-3 position-relative">
                  <label for="">ทีม: </label>
                  <multiselect
                    v-model="teamId"
                    label="name"
                    type="search"
                    :options="optionTeam"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    @search-change="getTeam()"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div> -->
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  <label>ตั้งแต่วันที่ : </label>
                  <date-picker
                    v-model="startDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    value-type="format"
                    lang="en"
                    @input="checkPermission()"
                  >
                  </date-picker>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  <label>ถึงวันที่ : </label>
                  <date-picker
                    v-model="endDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    value-type="format"
                    lang="en"
                    @input="checkPermission()"
                  >
                  </date-picker>
                </div>
              </div>
              <div class="col-md-3" v-if="userPriority >= 5">
                <div class="mb-3 position-relative">
                  <label>ประเภทการแสดงผล : </label>
                  <multiselect
                    v-model="listType"
                    :options="listTyeOptions"
                    label="nameTh"
                    @input="getDataKpiTeam()"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-end">
              <button class="btn btn-primary" @input="checkPermission()">
                ค้นหา
              </button>
            </div>
            <hr />

            <div class="row">
              <!-- //! Card -->
              <div class="col-md-6 col-xl-3">
                <!--   <div class="col-md-6 col-xl-3" @click="popupModal('appt')"> -->
                <div class="card" style="height: 170px">
                  <div class="card-body" @click="showmodal()">
                    <div class="float-end mt-2">
                      <i
                        class="uil uil-user-location"
                        style="font-size: 70px"
                      ></i>
                    </div>
                    <div>
                      <h4 class="mb-1 mt-1">
                        ลูกค้าที่รับผิดชอบ<br />
                        <span data-plugin="counterup">
                          <h2>
                            <countTo
                              :startVal="0"
                              :endVal="
                                dataDashboard.prospectCustomer
                                  ? dataDashboard.prospectCustomer
                                  : 0
                              "
                              :duration="2000"
                            ></countTo>
                          </h2>
                        </span>
                        คน
                      </h4>
                      <!-- <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- //! End Card -->

              <!-- //! Card -->
              <div class="col-md-6 col-xl-3">
                <!--   <div class="col-md-6 col-xl-3" @click="popupModal('appt')"> -->
                <div class="card" style="height: 170px">
                  <div class="card-body">
                    <div class="float-end mt-2">
                      <i class="uil uil-smile" style="font-size: 70px"></i>
                    </div>
                    <div>
                      <h4 class="mb-1 mt-1">
                        กำลังติดตาม<br />
                        <span data-plugin="counterup">
                          <h2>
                            <countTo
                              :startVal="0"
                              :endVal="
                                dataDashboard.activePPCus
                                  ? dataDashboard.activePPCus
                                  : 0
                              "
                              :duration="2000"
                            ></countTo>
                          </h2>
                        </span>
                        คน
                      </h4>
                      <!-- <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- //! End Card -->

              <!-- //! Card -->
              <div class="col-md-6 col-xl-3" style="color:green;">
                <!--   <div class="col-md-6 col-xl-3" @click="popupModal('appt')"> -->
                <div class="card" style="height: 170px">
                  <div class="card-body">
                    <div class="float-end mt-2">
                      <i class="uil uil-user-plus" style="font-size: 70px"></i>
                    </div>
                    <div>
                      <h4 class="mb-1 mt-1" style="color:green;">
                        <label>สำเร็จ</label><br />
                        <span data-plugin="counterup">
                          <h2>
                            <countTo
                              style="color:green;"
                              :startVal="0"
                              :endVal="
                                dataDashboard.successPPCus
                                  ? dataDashboard.successPPCus
                                  : 0
                              "
                              :duration="2000"
                            ></countTo>
                          </h2>
                        </span>
                        คน
                      </h4>
                      <!-- <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- //! End Card -->

              <!-- //! Card -->
              <div class="col-md-6 col-xl-3">
                <!--   <div class="col-md-6 col-xl-3" @click="popupModal('appt')"> -->
                <div class="card" style="color:red; height: 170px">
                  <div class="card-body">
                    <div class="float-end mt-2">
                      <i class="uil uil-user-minus" style="font-size: 70px"></i>
                    </div>
                    <div>
                      <h4 class="mb-1 mt-1" style="color:red;">
                        lost<br />
                        <span data-plugin="counterup">
                          <h2>
                            <countTo
                              style="color:red;"
                              :startVal="0"
                              :endVal="
                                dataDashboard.lostPPCus
                                  ? dataDashboard.lostPPCus
                                  : 0
                              "
                              :duration="2000"
                            ></countTo>
                          </h2>
                        </span>
                        คน
                      </h4>
                      <!-- <p class="text-muted mb-0">คลิกเพื่อดูรายละเอียด</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- //! End Card -->
            </div>

            <div class="row">
              <div class="card">
                <div class="card-body">
                  <b-overlay
                    v-if="!load"
                    :show="overlayFlag"
                    :spinner-variant="this.$overlayVariant"
                    :spinner-type="this.$overlayType"
                    :rounded="this.$overlayRounded"
                  >
                  </b-overlay>
                  <div class="row" v-else>
                    <div class="col-md-12 mt3">
                      <apexchart
                        v-if="
                          chart == true &&
                            this.Teamseries[0].data.length > 0 &&
                            userPriority >= 5
                        "
                        type="bar"
                        class="apex-charts"
                        dir="ltr"
                        height="500"
                        :options="optionsTeam"
                        :series="Teamseries"
                      ></apexchart>

                      <div class="text-center" v-else>
                        ไม่พบข้อมูล
                      </div>
                      <hr />
                    </div>
                    <div
                      class="col-md-12"
                      v-if="this.userPriority >= 5 && this.userPriority > 3"
                    >
                      <h4 class="card-title mb-4">Top Users</h4>

                      <div data-simplebar style="max-height: 500px;">
                        <div class="table-responsive">
                          <table
                            class="table table-borderless table-centered table-nowrap"
                          >
                            <tbody>
                              <tr
                                v-for="(item, index) in topUsers"
                                :key="index"
                              >
                                <td style="width: 20px;">
                                  <b>
                                    {{ index + 1 }}. &nbsp;&nbsp;&nbsp;
                                    <b-avatar variant="secondary" class="border border-secondary border-2 bg-secondary text-white"></b-avatar
                                  ></b>
                                </td>
                                <td>
                                  <h6 class="font-size-15 mb-1 fw-normal">
                                    {{ item.nameTh }}
                                  </h6>
                                  <p class="text-muted font-size-13 mb-0">
                                    <i class="mdi mdi-map-marker"></i>
                                    {{ item.branchNameTh }}
                                  </p>
                                </td>

                                <td class="text-muted fw-semibold text-end">
                                  <i
                                    class="icon-xs icon me-2 text-success"
                                    data-feather="trending-up"
                                  ></i>
                                  {{ item.price }}
                                </td>
                                <td class="text-muted fw-semibold text-end">
                                  <i
                                    class="icon-xs icon me-2 text-success"
                                    data-feather="trending-up"
                                  ></i>
                                  {{ item.total }} คน
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- enbd table-responsive-->
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div id="chart" v-if="userPriority >= 5">
                        <label for="">จำนวนลูกค้ามุ่งหวังแต่ละสาขา</label>
                        <apexchart
                          type="donut"
                          height="300px"
                          :options="chartOptionsPie"
                          :series="seriesPie"
                        ></apexchart>
                      </div>
                    </div>
                    <hr />

                    <div class="col-md-12">
                      <div id="chart" v-if="userPriority >= 5">
                        <label for=""
                          >จำนวนการเข้าถึงของลูกค้าแต่ละช่องทาง</label
                        >
                        <apexchart
                          type="pie"
                          height="400"
                          :options="chartOptionsSocialPie"
                          :series="seriesSocialPie"
                        ></apexchart>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div id="chart" v-if="userPriority >= 5">
                        <label for="">จำนวนลูกค้า ที่สนใจรถแต่ละรุ่น</label>
                        <apexchart
                          type="pie"
                          height="400"
                          :options="chartOptionsVehicleTypePie"
                          :series="seriesVehicleTypePie"
                        ></apexchart>
                      </div>
                    </div>
                    

                    <div class="col-md-12">
                      <div id="chart" v-if="userPriority >= 5">
                        <label for="">ช่วงเงินเดือนของลูกค้ามุ่งหวัง</label>
                        <apexchart
                          type="pie"
                          height="400"
                          :options="chartOptionsSalaryPie"
                          :series="seriesSalaryPie"
                        ></apexchart>
                      </div>
                    </div>
                    

                    <div class="col-md-12">
                      <div id="chart" v-if="userPriority >= 5">
                        <label for="">อาชีพของลูกค้ามุ่งหวัง</label>
                        <apexchart
                          type="pie"
                          height="400"
                          :options="chartOptionsOccPie"
                          :series="seriesOccPie"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab v-if="userPriority >= 5">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">ช่วงปี</span>
            </template>

            <div class="row">
              <div class="col-sm-4 col-md-3" v-if="userPriority >= 5">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"
                    >จากปี (ค.ศ):</label
                  >
                  <multiselect
                    v-model="startYear"
                    :options="optionYear"
                    label="id"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-sm-4 col-md-3" v-if="userPriority >= 5">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"
                    >ถึงปี (ค.ศ):</label
                  >
                  <multiselect
                    v-model="endYear"
                    :options="optionYear"
                    label="id"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-md-6">
                <label for=""></label><br />
                <button
                v-if="userPriority >= 5"
                  class="btn btn-primary"
                  @click="getCompare()"
                  :disabled="!endYear.id && !startYear.id"
                >
                  ค้นหาข้อมูล
                </button>
              </div>
            </div>

            <b-overlay
              v-if="!load"
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
            </b-overlay>
            <div class="row">
              <div
                class="col-md-12"
                v-if="this.userPriority >= 5 && this.userPriority > 3"
              >
                <h4 class="card-title mb-4">สาขา</h4>

                <div data-simplebar style="max-height: 500px;">
                  <div class="table-responsive">
                    <table
                      class="table table-borderless table-centered table-nowrap"
                    >
                      <tbody>
                        <tr v-for="(item, index) in compareData" :key="index">
                          <td style="width: 20px;">
                            <b> <i class="mdi mdi-map-marker"></i></b>
                          </td>
                          <td>
                            <h6 class="font-size-15 mb-1 fw-normal">
                              {{ item.nameTh }}
                            </h6>
                            <!-- <p class="text-muted font-size-13 mb-0">
                                    <i class="mdi mdi-map-marker"></i>
                                    {{ item.branchNameTh }}
                                  </p> -->
                          </td>

                          <td class="text-muted fw-semibold text-end">
                            <p
                              class="text-muted mb-0"
                              v-if="calComparePer(item.year) > 0"
                            >
                              <b style="color: green"
                                >+ {{ calComparePer(item.year) }} คน (
                                {{ startYear.id }}-{{ endYear.id }} )</b
                              >
                            </p>
                            <p
                              class="text-muted mb-0"
                              v-if="calComparePer(item.year) == 0"
                            >
                              <b 
                                > {{ calComparePer(item.year) }} คน (
                                {{ startYear.id }}-{{ endYear.id }} )</b
                              >
                            </p>
                            <p class="text-muted mb-0" v-if="calComparePer(item.year) < 0">
                              <b style="color: red"
                                >- {{ calComparePer(item.year) }} คน (
                                {{ startYear.id }}-{{ endYear.id }} )</b
                              >
                            </p>
                          </td>
                          <td class="text-muted fw-semibold text-end">
                            <i
                              class="icon-xs icon me-2 text-success"
                              data-feather="trending-up"
                            ></i>
                            {{ item.total }} คน
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- enbd table-responsive-->
                </div>
              </div>
            </div>
            <div class="col-md-12" v-if="userPriority >= 5" >
              <div id="chart">
                <apexchart
                v-if="userPriority >= 5"
                  type="line"
                  height="350"
                  :options="optionsCompare"
                  :series="seriesCompare"
                ></apexchart>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal
      ref="modalShow"
      :title="titleModal"
      hide-footer
      size="xl"
      @hide="handleHide"
    >
      <div class="row">
        <tableData :fields="fields" :items="showTB" v-show="showTB.length > 0">
          <template #cell(index)="showTB">
            {{ showTB.index + 1 }}
          </template>
        </tableData>
        <div class="col-md-12 text-center">
          <b v-show="showTB.length == 0">ไม่พบข้อมูล</b>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
